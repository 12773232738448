




















import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'dao-label': () => import('./dao-label.vue'),
  },
})
export default class DaoArea extends Vue {
  @Prop() title!: string
  @Prop() daos!: any[]
  @Prop({ default: 'no data' }) placeholder!: string
}
